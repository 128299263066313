<!-- Settings -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                    <div class="col-xl-12 col-xxl-7">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <div class="row">
                                    <div class="col-xl-10 title-name">
                                        <h2 class="mt-10 font-weight-bold text-dark"><i class="flaticon-user settingicon"></i> {{view.cat_name}}</h2>
                                    </div>
                                    <div class="col-xl-2">
                                        <h2 class="mb-10 font-weight-bold text-dark edit"><a @click="editBrand(view.brand_id)"><i class="flaticon2-pen settingicon" ></i></a></h2>
                                    </div>
                                    
                                    <div class="col-xl-8 title-width">
                                        <div class="form-group">
                                            <label><span><b>Brand ID: </b></span></label><span> {{view.brand_id}}</span>
                                        </div>
                                        <div class="form-group">
                                            <label><span><b>Brand Name: </b></span></label><span> {{view.brand_name}}</span>
                                        </div>
                                        
                                       <!-- <div class="form-group">
                                            <label><span><b>Product Count: </b></span></label><span> {{view.cat_id}}</span>
                                        </div> -->
                                        <div class="form-group">
                                            <label><span><b>Status:</b></span></label><span v-if="view.record_status === 1"> Active</span><span v-else> Deactive</span>
                                        </div>
                                    </div>

                                   
                                </div>
                                
                              
                            </div>
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getBrand} from "@/api/product";
export default {
  name: 'view-category',
  components: {},
   data() {
    return {
      loading: false,
      view:[]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Brand", route: "/product/brand" },
      { title: "View Brand" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
  fetchData() {
      this.loading = true
      getBrand(this.$route.params.brand_id).then(response => {
        this.view = response.data.data[0]
        this.loading = false
      });
    },
     editBrand(brand_id){
    this.$router.push({path:'/product/edit-brand/'+brand_id})
    },
   }
   
};
</script>